<template>
  <div>
    <header-four />
    <main>
      <slot />
    </main>
    <footer-two />
    <back-to-top />
    <!-- <modal-product /> -->
  </div>
</template>
